import React from "react";

const Arrow = ({ width = 37, height = 37 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.7013 36.2766L7.78394 36.1125C7.37313 36.0955 6.98302 35.9198 6.69578 35.6224C6.40855 35.325 6.24662 34.929 6.24411 34.5181C6.24161 34.1071 6.39872 33.7131 6.68235 33.4192C6.96598 33.1252 7.35398 32.9543 7.76462 32.9423L30.8518 33.0811L0.477373 2.70666C0.178104 2.40739 0.00853637 2.00294 0.00597123 1.58227C0.00340746 1.16161 0.168058 0.759188 0.4637 0.463547C0.759341 0.167905 1.16176 0.00325585 1.58242 0.00581962C2.00309 0.00838476 2.40754 0.177953 2.70681 0.477221L33.0813 30.8517L32.9424 7.76447C32.9544 7.35383 33.1254 6.96583 33.4193 6.6822C33.7133 6.39857 34.1072 6.24146 34.5182 6.24396C34.9292 6.24647 35.3251 6.4084 35.6225 6.69563C35.92 6.98287 36.0957 7.37298 36.1127 7.78379L36.2768 34.7012C36.2791 35.1215 36.1144 35.5235 35.819 35.8189C35.5236 36.1143 35.1216 36.2789 34.7013 36.2766Z"
        fill="white"
      />
    </svg>
  );
};

export default Arrow;
